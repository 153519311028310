const StatusIcon = ({ status, link }) => {
    const getStatusStyle = () => {
      switch (status) {
        case 'confirmed':
          return { color: '#4CAF50' }; // Green
        case 'unconfirmed':
          return { color: '#FFC107' }; // Yellow/Orange
        default:
          return { color: '#9E9E9E' }; // Grey
      }
    };
  
    const getStatusText = () => {
      switch (status) {
        case 'confirmed':
          return 'On-Chain';
        case 'unconfirmed':
          return 'Pending Confirmation';
        default:
          return 'Status unknown';
      }
    };
  
    return (
      <div 
        className="status-icon" 
        style={{
          ...getStatusStyle(),
          position: 'absolute',
          bottom: '8px',
          right: '8px',
          fontSize: '14px'
        }}
        title={getStatusText()} // This adds the native browser tooltip
        onClick={(e) => {
          e.stopPropagation();  // Add this line
          link && window.open(link, '_blank');
        }}
      >
        {status === 'confirmed' ? '🟠' : '⚪'}
      </div>
    );
  };

  export default StatusIcon;