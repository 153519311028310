import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Wallet from 'sats-connect';
import { AddressPurpose } from "sats-connect";
import { services } from '../services/service.js';
import PopupData from './PopupData.js';
import '../css/fab.css';

function FloatingActionButton() {
  const navigate = useNavigate();
  const [showFabMenu, setShowFabMenu] = useState(false);
  const [connected, setConnected] = useState(false);
  const [shortAddress, setShortAddress] = useState('');
  const [showChangeName, setShowChangeName] = useState(false);
  
  // Popup related states
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [data, setData] = useState(null);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const isXverseBrowser = navigator.userAgent.includes('Xverse');
  
  useEffect(() => {
    // Check wallet and name status on mount
    if (window.localStorage.getItem('walletStatus')) {
      const ordinalAddress = window.localStorage.getItem('ordinalAddress');
      setConnected(true);
      const truncatedText = truncateString(ordinalAddress);
      setShortAddress(truncatedText);

      if (window.localStorage.getItem('selectedUsername')) {
        const name = JSON.parse(window.localStorage.getItem('selectedUsername'));
        setShortAddress(name.name_escaped);
        setShowChangeName(true);
      }
    }
  }, []);

  // Click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.fab-container')) {
        setShowFabMenu(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const getNameByAddress = async (address) => {
    const names = await services.getNameByAddress(address);
    return names;
  };

  const connectWallet = async () => {
    // If on mobile but not in Xverse browser, redirect to Xverse
    if (isMobile && !isXverseBrowser) {
      const currentUrl = window.location.href;
      const xverseDeepLink = `https://connect.xverse.app/browser?url=${encodeURIComponent(currentUrl)}`;
      window.location.href = xverseDeepLink;
      return;
    }

    const response = await Wallet.request('getAccounts', {
      purposes: [AddressPurpose.Payment, AddressPurpose.Ordinals],
      message: 'Bitter.lol needs your BTC addresses!',
      network: { type: process.env.REACT_APP_BTCNETWORK }
    });
    
    if (response.result) {
      window.localStorage.setItem('walletStatus', true);
      window.localStorage.setItem('paymentAddress', response.result[0].address);
      window.localStorage.setItem('ordinalAddress', response.result[1].address);
      window.localStorage.setItem('paymentPubKey', response.result[0].publicKey);
      window.localStorage.setItem('ordinalPubKey', response.result[1].publicKey);
      setConnected(true);
      const truncatedText = truncateString(response.result[1].address);
      setShortAddress(truncatedText);
      getNamesByAddress(response.result[1].address);
    }
    setShowFabMenu(false);
  };

  const getNamesByAddress = async () => {
    const ordinalAddress = window.localStorage.getItem('ordinalAddress');
    const namesbyaddress = await getNameByAddress(ordinalAddress);
    if (namesbyaddress && namesbyaddress.count) {
      setIsPopupOpen(true);
      setData(namesbyaddress.names);
    } else if (!namesbyaddress.count) {
      navigate('/register');
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    if (window.localStorage.getItem('selectedUsername')) {
      const name = JSON.parse(window.localStorage.getItem('selectedUsername'));
      setShortAddress(name.name_escaped);
      setShowChangeName(true);
    }
  };

  const disconnectWallet = async () => {
    await Wallet.disconnect();
    window.localStorage.removeItem('walletStatus');
    window.localStorage.removeItem('ordinalAddress');
    window.localStorage.removeItem('paymentAddress');
    window.localStorage.removeItem('paymentPubKey');
    window.localStorage.removeItem('ordinalPubKey');
    window.localStorage.removeItem('selectedUsername');
    setConnected(false);
    setShowChangeName(false);
    setShowFabMenu(false);
  };

  const truncateString = (str) => {
    if (!str) return '';
    const prefixLength = 4;
    const suffixLength = 4;
    const ellipsis = '...';
    
    if (str.length <= prefixLength + suffixLength) return str;
    
    const start = str.slice(0, prefixLength);
    const end = str.slice(-suffixLength);
    return `${start}${ellipsis}${end}`;
  };

  return (
    <>
      <div className="fab-container">
        <button 
          className={`fab-button ${showFabMenu ? 'active' : ''} ${connected ? 'connected' : 'disconnected'}`} 
          onClick={() => setShowFabMenu(!showFabMenu)}
        >
          {showFabMenu ? '😣' : '😣'} 
        </button>
        
        {showFabMenu && (
          <div className="fab-menu">
            {!connected ? (
              <button className="fab-item" onClick={connectWallet}>
                <span className="fab-icon">🔐</span>
                <span className="fab-label">
                  {isMobile && !isXverseBrowser ? 'Open in Xverse Wallet' : 'Connect Wallet'}
                </span>
              </button>
            ) : (
              <>
                <button className="fab-item" onClick={disconnectWallet}>
                  <span className="fab-icon">🔓</span>
                  <span className="fab-label">Disconnect ({shortAddress})</span>
                </button>
                {showChangeName && (
                  <button className="fab-item" onClick={getNamesByAddress}>
                    <span className="fab-icon">🔄</span>
                    <span className="fab-label">Change Name</span>
                  </button>
                )}
                <button className="fab-item">
                <Link to="/register" onClick={() => setShowFabMenu(false)}>
                  <span className="fab-icon">📛</span>
                  <span className="fab-label">Register Name</span>
                </Link>
                </button>
                <button className="fab-item">
                <Link to="/add" onClick={() => setShowFabMenu(false)}>
                  <span className="fab-icon">✏️</span>
                  <span className="fab-label">Create Post</span>
                </Link>
                </button>
              </>
            )}
          </div>
        )}
      </div>
      {data && <PopupData open={isPopupOpen} handleClose={handleClosePopup} data={data} />}
    </>
  );
}

export default FloatingActionButton;