import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import StatusIcon from '../components/StatusIcon.js'; // Add this import


function Post({ post, redirectURL }) {
  const navigate = useNavigate();
  const formattedUsername = encodeURIComponent((post.name));

  const handleReplyClick = (e, replytoID) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/reply?replytoID=${replytoID}`);
  };

  const handlePostClick = (e) => {
    if (redirectURL) {
      if (e.ctrlKey || e.metaKey || e.button === 1) {
        window.open(redirectURL, '_blank').focus();
      } else {
        navigate(redirectURL);
      }
    }
  };
  const handleLinkClick = (e) => {
    e.stopPropagation(); // Stop the click from bubbling up to the parent div
  };

  return (
    <div className="post" onClick={handlePostClick}>
      <p className="content">
        {post.content.split(/(https?:\/\/\S+)/g).map((part, index) =>
          part.match(/https?:\/\/\S+/) ? (
            <Link key={index} to={part} target="_blank" rel="noopener noreferrer">
              {part}
            </Link>
          ) : (
            part
          )
        )}
      </p>
      <p>
        {post.replytoID ? (
          <>
            <Link onClick={handleLinkClick}to={`/user?user=${formattedUsername}`}>{post.name}</Link> replying to{' '}
            <Link onClick={handleLinkClick}to={`/post?id=${post.replytoID}`}>{post.replytoID.slice(0, 8)}...</Link> {' '}
            {post.status === 'confirmed' && (
              <> at {new Date(post.timestamp * 1000).toLocaleString()}</>
            )}
            <button className='link' onClick={(e) => handleReplyClick(e, post.inscriptionID)}>
              +reply
            </button>
          </>
        ) : (
          <>
            posted by <Link onClick={handleLinkClick}to={`/user?user=${formattedUsername}`}>{post.name}</Link> {' '}
            {post.status === 'confirmed' && (
              <> at {new Date(post.timestamp * 1000).toLocaleString()}</>
            )}
            <button className='link' onClick={(e) => handleReplyClick(e, post.inscriptionID)}>
              +reply
            </button>
          </>
        )}
      </p>
      <StatusIcon 
        status={post.status} 
        link={post.inscriptionID ? 
          post.status === 'confirmed' 
            ? `https://ordinals.com/inscription/${post.inscriptionID}`
            : `https://mempool.space/tx/${post.txid}` // or whatever URL you want for unconfirmed
          : null
        } 
      />
    </div>
  );
}

export default Post;
